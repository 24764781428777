//Configure external link URLs based on current host

export const URLSUFFIX_CONTACT_US = '/feconnect/interconnection-contacts.html'
export const URLSUFFIX_PAY_INVOICE = '/help/billingpayments/ways-to-pay/non-consumption-payments.html'
export const URLSUFFIX_REQUEST_ACCESS = '/supplierservices.html'
export const URLSUFFIX_CUSTOMER_REGISTER = '/register_for_onlineaccount.html'
export const URLSUFFIX_FORGOT_PASSWORD = '/log_in/forgot-credentials.html'
export const URLSUFFIX_REGISTRATION_AGREEMENT = '/register_for_onlineaccount/username-and-password-guidelines.html'
export const URLSUFFIX_CARE_CENTER = '/help/contact-center.html'
export const URLSUFFIX_REQUEST_UPGRADE = '/service_requests/request-electrical-work/new-upgrade-service.html'
export const URLSUFFIX_WHOLESALE = '/feconnect/wholesale-interconnections.html'

const devB2CHost = 'https://b2cdevlogin.firstenergycorp.com/551ca087-06a5-4de8-abfd-7752f5d91ce3/oauth2/v2.0/authorize?client_id=71a3e71b-643b-4d06-b378-297becb50bf1';
const qaB2CHost = 'https://qab2clogin.firstenergycorp.com/08b26523-5011-4c9b-a113-720dd0682247/oauth2/v2.0/authorize?client_id=9e4a606e-2be0-407b-a350-29e767a3a2a2';
const prodB2CHost = 'https://b2clogin.firstenergycorp.com/d8077861-d8d1-43a9-82f7-65b6e0ecffad/oauth2/v2.0/authorize?client_id=58b150e7-1cff-48f2-b6e6-6c8312a3fe24';

//map from the current host origin to the correct external link host/prefix and B2C login host
const envHosts:  {hostContains: string, externalHost: string, b2cHost: string}[]= [
    {hostContains: 'localhost:', externalHost: 'https://www-qa-b.firstenergycorp.com', b2cHost: devB2CHost},
    {hostContains: 'interconnect.dev', externalHost: 'https://www-qa-b.firstenergycorp.com', b2cHost: devB2CHost},
    {hostContains: 'interconnect.qa', externalHost: 'https://www-qa-b.firstenergycorp.com', b2cHost: qaB2CHost},
    {hostContains: 'firstenergycorp.com', externalHost: 'https://www.firstenergycorp.com', 
        b2cHost: prodB2CHost}, 
    {hostContains: '/', externalHost: 'https://www.firstenergycorp.com',  //default, including internal prod URLs
        b2cHost: prodB2CHost}, 
];

/** Constructs an external FirstEnergy URL based on the given URL suffix. 
 * Takes into account the current host to prepend the correct FirstEnergy environment for the link URL. */
export default function GetExternalFeUrl(urlSuffix: string ) {
    const currentHost = window.location.origin;
    return envHosts.find(h => currentHost.includes(h.hostContains))?.externalHost + urlSuffix;
}

/** Get the B2C login URL for this environment, redirecting back to the current page */
export function getB2cUrl() {
    //host, client ID, and redirect URL changes per env, but rest is static
    const currentHost = window.location.origin;
    const host = envHosts.find(h => currentHost.includes(h.hostContains))?.b2cHost;
    return `${host}&nonce=defaultNonce&p=B2C_1A_SIGNIN`
				+ `&redirect_uri=${encodeURIComponent(window.location.href.replace(window.location.hash,""))}&scope=openid&response_type=id_token&prompt=login`;
}

export function getBackendHost() {
    return process.env.REACT_APP_SERVER_URL ?? window.location.origin;
}