import Table from "../../table/Table";
import React, {useEffect, useRef, useState} from "react";
import {AttachmentResponse, AttachmentStatus, AttachmentType, hiddenAttachmentTypes} from "../../../models/AttachmentResponse";
import {Column} from "react-table";
import {
    FlexColumnContainer,
    GreenCheckmark,
    GridColumnContainer,
    PageTitle,
    WrapDate
} from "../../../styledcomponents/MiscStyledComponents";
import {useAxios} from "../../../AxiosProvider";
import {useMessageService} from "../../../hooks/useMessageService";
import {FormProvider, SubmitHandler, useFieldArray, useForm} from "react-hook-form";
import {AttachmentFormData} from "../forms/AttachmentForm";
import Modal from "react-modal";
import {ModalStyles} from "./ConfirmationModal";
import {
    Form,
    InsideCollapsibleHeader,
    SmallPrimaryButton,
    SmallSecondaryButton,
    StyledLinkButton
} from "../../../styledcomponents/FormStyledComponents";
import Delete from "../../../assets/images/Delete.svg";
import {Select} from "../../form/FormElements";
import GreyBanner from "../../misc/GreyBanner";
import styled from "styled-components";
import ExternalLink from "../../misc/ExternalLink";
import ErrorUtils from "../../../utils/errorUtils";
import { encode } from "base64-arraybuffer";
import ApplicationFormUtils from "../../../utils/applicationFormUtils";

const RequiredText = styled.span`
    color: #A52B1D;
`

const FileNameText = styled.span`
    margin-right: 0.5rem;
    vertical-align: middle;
`

const DeleteButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    border-radius:3px;
    padding:3px 5px;
    &:hover {
        color: #000;
        background-color:#dde
    }
`

const DeleteImg = styled.img`
    height: 16px;
  vertical-align: middle;
`

const allowedExtensions = ['xls','xlsx','zip','jpg','jpeg','jfif','txt','pdf','csv','doc','docx'];
const allowedExtensionsString = allowedExtensions.map(e => '.' + e, ).join(', ');
const allowedExtensionsDescription = 'Excel files (.xls,.xlsx), Word documents (.doc, .docx), ZIP archives (.zip), JPEG images (.jpg, .jpeg), PDF files (.pdf), CSV files (.csv),  and Text files (.txt)'

type UploadedDocumentsTableProps = {
    applicationId: string;
    uploadAllowed: boolean;
    deleteAllowed: boolean;
    requiredFileTypes?: AttachmentType[];
    setLastKnownUploads?: (a : AttachmentType[]) => void
}

export function UploadedDocumentsTable(props : UploadedDocumentsTableProps) {
    const axios = useAxios();
    const messageService = useMessageService();

    const methods = useForm<AttachmentFormData>({
        defaultValues: {
            files: []
        }
    });
    const {handleSubmit, watch, formState : {isSubmitting, isDirty}, control} = methods;
    let watchFiles = watch("files");
    const { fields, replace, remove } = useFieldArray({
        name: 'files',
        control
    });

    const [saveDisabled, setSaveDisabled] = useState<boolean>(true);
    const [loadingFiles, setLoadingFiles] = useState<boolean>(false);
    const [uploadedFiles, setUploadedFiles] = useState<AttachmentStatus[]>([]);
    const [deleteFileName, setDeleteFileName] = useState<string>('');
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const fileInputRef = useRef<HTMLInputElement>(null);

    let columns : Column<AttachmentStatus>[] = [
        {
            Header: 'Type',
            id: 'type',
            accessor: 'type'
        },
        {
            Header: 'Name',
            id: 'fileName',
            accessor:(row: AttachmentStatus ) => <StyledLinkButton onClick={() => downloadFile(row.fileName)}>{row.fileName}</StyledLinkButton>
        },
        {
            Header: 'Date/Time',
            id: 'createdDate',
            accessor: (row: AttachmentStatus ) => <WrapDate>{new Date(row.createdDate).toLocaleString()}</WrapDate>
        },
        ... props.deleteAllowed
            ? [{
        Header: 'Delete',
        id: 'delete',
        Cell: ({ row }: {row: any}) => (
            // Use Cell to render an expander for each row.
            // We can use the getToggleRowExpandedProps prop-getter
            // to build the expander.
            <DeleteButton onClick={() => handleExistingFileDelete(row?.original?.fileName)}>
                <DeleteImg src={Delete} alt={"delete icon"} />
            </DeleteButton>
        ),
        width:50}]
            : []
    ];

    const getUploadedfiles =  async () => {
        setLoadingFiles(true);
        return axios?.secureApi.get(
            'api/attachments/' + props.applicationId
        ).then(response => {
            let attachmentResponse: AttachmentResponse = response.data;
            setUploadedFiles(attachmentResponse.attachmentsStatus);
            if (props.uploadAllowed && !!props.setLastKnownUploads) {
                props.setLastKnownUploads(attachmentResponse.attachmentsStatus.map(s => s.type));
            }

            // if attachments meet requirements, then set attachments complete
            return attachmentResponse.attachmentsStatus;
        }).catch(e => {
            messageService.error(ErrorUtils.UNKNOWN_ERROR);
        }).finally(() => {
            setLoadingFiles(false);
        })
    }
    async function downloadFile(filename: string) {
            if (filename?.length) {
                let base64FileName = encode(new TextEncoder().encode(filename).buffer as ArrayBuffer);//NOSONAR
                axios?.secureApi.get(
                    `api/attachments/${props.applicationId}/${base64FileName}`,
                    {responseType: 'blob'}
                ).then(response => {
                    const type = response.headers['content-type']
                    const blob = new Blob([response.data], { type: type })
                    const link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = filename;
                    link.click()
                }).catch(e => {
                    messageService.error(ErrorUtils.UNKNOWN_ERROR);
                })
            }
        }

    const onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!!event.target.files) {
            let files = Array.from(event.target.files);
            files.length = Math.min(files.length, 5);
            replace(files.map(file => {
                return {
                    name: file.name,
                    type: AttachmentType.SELECT_ONE,
                    file
                };
            }))
            openModal();
        } else {
            replace([]);
        }
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    }

    const onBrowseButtonClick = () => {
        fileInputRef.current?.click();
    }

    const openModal = () => {
        setIsModalOpen(true);
    }

    const closeModal = () => {
        if (fileInputRef.current) {
            //Clear upload on cancel so the onChange works if they choose the same file later
            fileInputRef.current.value = ''
        }
        replace([]);
        setIsModalOpen(false);
    }

    // Used to remove a file that hasn't yet been uploaded from the list
    const handleFileRemove = (index: number) => {
        remove(index);

        // If you have removed the last file, close the modal.
        if (fields.length === 1) {
            closeModal();
        }
    }

    function handleExistingFileDelete(fileName: string) {
        setDeleteFileName(fileName);
    }

    async function deleteExistingFile() {
        if (deleteFileName?.length) {
            //normal btoa() has issues with non-ASCII characters on some browsers
            const buf = new TextEncoder().encode(deleteFileName);
            await axios?.secureApi.delete(`api/attachments/delete/${props.applicationId}/${encode(buf.buffer as ArrayBuffer)}`)//NOSONAR
            await getUploadedfiles();
            setDeleteFileName('');
        }
    }

    useEffect(() => {
        setSaveDisabled(isSubmitting || !isDirty);
    }, [isSubmitting, isDirty]);

    useEffect(() => {
        getUploadedfiles();
    }, []);

    const onSubmit : SubmitHandler<AttachmentFormData> = async (data : AttachmentFormData) => {
        // clear any messages once we submit
        messageService.clearAll();

        // attempt to upload all attachments
        const formData = ApplicationFormUtils.buildUploadFileFormData(data.files, props.applicationId);

        await axios?.secureApi.post(
            '/api/attachments/upload', formData
        ).then(response => {
            let attachmentResponse: AttachmentResponse = response.data;
            if (attachmentResponse.errorMessage) {
                messageService.error(`Failed to upload files.`);
                return;
            }
            // Analyze the response and remove any that didn't succeed
            attachmentResponse.attachmentsStatus.forEach(attachmentStatus => {
                const message = ApplicationFormUtils.getUploadFailedMessage(attachmentStatus.status, attachmentStatus.fileName, allowedExtensionsString)
                if (message) {
                    messageService.error(message);
                }
            });
        }).catch(e => {
            messageService.error(`File failed to upload.`);
        });

        replace([]);
        setIsModalOpen(false);

        // fetch all the uploaded files after save
        await getUploadedfiles()
    };

    return (
        <div>
            {
                props.uploadAllowed && !!props.requiredFileTypes &&
                <div>
                    <InsideCollapsibleHeader >Select Files To Upload</InsideCollapsibleHeader>
                    In order to process your application, upload the documents listed below (as well as any additional documents).  Do not attempt upload more than 5 files at once.  File size can be up to 100 MB per file.  Supported file types are: {allowedExtensionsDescription}.
                    <ul>
                        {
                            props.requiredFileTypes!.map(attachmentType => <li key={attachmentType}>
                                {uploadedFiles?.find(f => f.type === attachmentType) &&
                                    <GreenCheckmark size={14} style={{paddingRight:"0.5rem"}} />
                                }
                                {attachmentType}
                                <RequiredText> (Required)</RequiredText>
                            </li>)
                        }
                    </ul>
                </div>
            }
            {props.uploadAllowed && <input id={"attachmentFileInput"} ref={fileInputRef} onChange={onFileInputChange} style={{display: "none"}} type={"file"} multiple accept={allowedExtensionsString}/>}
            {props.uploadAllowed && !!props.requiredFileTypes && !loadingFiles &&
                <FlexColumnContainer>
                    <SmallPrimaryButton float={'left'} id={"attachmentBrowse"} onClick={onBrowseButtonClick}>Browse</SmallPrimaryButton>
                </FlexColumnContainer>
            }
            {loadingFiles && <b>Loading uploaded files...</b>}
            {
                uploadedFiles.length > 0 && <div>
                    {(props.uploadAllowed && !props.requiredFileTypes)
                        ? <GreyBanner noMargin={true} marginTop={false} title={"Uploaded Documents"} button={<StyledLinkButton id={"attachmentBrowse"} onClick={onBrowseButtonClick}>{"Add Additional Documents"}</StyledLinkButton>}/>
                        : <GreyBanner noMargin={true} marginTop={props.uploadAllowed} title={"Uploaded Documents"}/>
                    }
                    <Table columns={columns} data={uploadedFiles.filter(t => !hiddenAttachmentTypes.includes(t.type))} isPaginatedSorted={false}/>
                </div>
            }
            <Modal isOpen={isModalOpen} onRequestClose={closeModal} style={ModalStyles}>
                <FormProvider {...methods}>
                    <PageTitle >Upload Documents</PageTitle>
                    <GridColumnContainer columns={2}>
                        <b>File Name</b>
                        <b>Document Type</b>
                    </GridColumnContainer>
                    <p/>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        {
                            fields.map((field, index) =>
                                <GridColumnContainer columns={2} key={field.id}>
                                    <div>
                                        <ExternalLink href={URL.createObjectURL(field.file)}><FileNameText>{field.name}</FileNameText></ExternalLink>
                                        <DeleteButton onClick={() => handleFileRemove(index)}>
                                            <DeleteImg src={Delete} alt={"delete icon"} />
                                        </DeleteButton>
                                    </div>
                                    <Select id={`files.${index}.type`} name={`files.${index}.type`} value={field.type}>
                                        {
                                            Object.values(AttachmentType)
                                                .filter(t => !hiddenAttachmentTypes.includes(t))
                                                .sort()
                                                .sort((a, b) => {
                                                    if (a === AttachmentType.SELECT_ONE) {
                                                        return -1;
                                                    } else if (b === AttachmentType.SELECT_ONE) {
                                                        return 1;
                                                    }
                                                    if (!!props.requiredFileTypes) {
                                                        if (props.requiredFileTypes!.includes(a)) {
                                                            if (props.requiredFileTypes!.includes(b)) {
                                                                return 0;
                                                            }
                                                            return -1;
                                                        } else if (props.requiredFileTypes!.includes(b)) {
                                                            return 1;
                                                        }
                                                    }
                                                    return 0;
                                                })
                                                .map(type => {
                                                let optionName = type.valueOf();
                                                if (!!props.requiredFileTypes && props.requiredFileTypes!.includes(type)) {
                                                    optionName += " (Required)";
                                                }
                                                return <option value={type.valueOf()} key={type}>{optionName}</option>
                                            })
                                        }
                                    </Select>
                                </GridColumnContainer>
                            )
                        }
                        <div>
                            <SmallSecondaryButton onClick={closeModal} float={"left"}>Cancel</SmallSecondaryButton>
                            <SmallPrimaryButton type={"submit"} float={"right"} disabled={saveDisabled || Boolean(watchFiles.find(f => f.type === AttachmentType.SELECT_ONE))}>
                                {isSubmitting ? 'Uploading...' : 'Upload'}
                            </SmallPrimaryButton>
                        </div>
                    </Form>
                </FormProvider>
            </Modal>
            {props.deleteAllowed && <Modal isOpen={Boolean(deleteFileName)} onRequestClose={() => setDeleteFileName('')} style={ModalStyles}>
                <h3>Delete Attachment</h3>
                <p>Are you sure you want to delete file <b>{deleteFileName}</b>?</p>
                <br/>
                <SmallSecondaryButton onClick={() => setDeleteFileName('')} float={"left"}>Cancel</SmallSecondaryButton>
                <SmallPrimaryButton onClick={deleteExistingFile}> Delete </SmallPrimaryButton>
            </Modal>}
        </div>
    )
}