import {FlexRowContainer, GridColumnContainer} from "../../../../styledcomponents/MiscStyledComponents";
import {Label, StyledOption} from "../../../../styledcomponents/FormStyledComponents";
import {ConfirmInput, ControlledPhoneInput, Input, RadioInput, Select} from "../../../form/FormElements";
import {Pattern} from "../../../../enums/Pattern";
import React, {useEffect, useState} from "react";
import {useFormContext, FormProvider} from "react-hook-form";
import {InterconnectApplicantFormData} from "./types/InterconnectApplicantTypes";
import {AxiosError, AxiosResponse} from "axios";
import {useAxios} from "../../../../AxiosProvider";
import {useMessageService} from "../../../../hooks/useMessageService";

type OperatingCompany = {
    id : string,
    name : string,
    abbreviation : string
}

type AccountHolderFormProps = {
    canChooseEmail ?: boolean
    canChooseOperatingCompany ?: boolean
    operatingCompanyId ?: string
    canEnterSocialSecurityNumber ?: boolean
}

export function AccountHolderForm(props : AccountHolderFormProps) {
    const axios = useAxios();
    const messageService = useMessageService();
    const methods = useFormContext<InterconnectApplicantFormData>();

    const {watch, getValues, resetField, formState : {errors}} = methods
    let watchLocationHome = watch('locationHome');

    const [operatingCompanies, setOperatingCompanies] = useState<OperatingCompany[]>([]);
    useEffect(() => {
        if (operatingCompanies.length === 0 && props.canChooseOperatingCompany) {
            axios?.secureApi.get(
                `/api/dropdown/restricted/operatingCompanies`
            ).then(response => {
                setOperatingCompanies(response.data);

                let opcoValue = getValues('operatingCompanyAbbreviation');

                // if there is no opcoValue set and there's an operatingCompanyId then get the obcoValue from
                // the list of operating companies returned from the server
                if (!opcoValue && props.operatingCompanyId) {
                    let opco : OperatingCompany = response.data.find((opco : OperatingCompany) => {
                        return opco.id == props.operatingCompanyId;
                    });

                    opcoValue =  opco.abbreviation;
                }

                resetField('operatingCompanyAbbreviation', {defaultValue : opcoValue});
            }).catch((err : AxiosError) => {
                let errorResponse : AxiosResponse | undefined = err.response;

                if (errorResponse?.status != 401) {
                    messageService.error("Error loading Operating Companies");
                }
            })
        }
    }, []);

    useEffect(() => {
        if (watchLocationHome == true.toString()) {
            resetField('businessName', {defaultValue: ''})
        } else {
            resetField('firstName', {defaultValue: ''})
            resetField('lastName', {defaultValue: ''})
        }
    }, [watchLocationHome])

    return (
        <FormProvider {...methods}>
            <FlexRowContainer>
                <Label htmlFor={"locationHome"} checkbox={true} >
                    <RadioInput name={'locationHome'} value={true.toString()} />
                    Person
                </Label>
                <Label htmlFor={"locationHome"} checkbox={true} >
                    <RadioInput name={'locationHome'} value={false.toString()} />
                    Business/Solar Community
                </Label>
            </FlexRowContainer>
            {/*If isPerson is yes, then show first and last name*/}
            {
                watchLocationHome == true.toString() &&
                <GridColumnContainer columns={2}>
                    <div>
                        <Label htmlFor={"firstName"} >First Name</Label>
                        <Input name={"firstName"} required={true} maxLength={25} error={errors?.firstName}/>
                    </div>

                    <div>
                        <Label htmlFor={"lastName"} >Last Name</Label>
                        <Input name={"lastName"} required={true} maxLength={25} error={errors?.lastName}/>
                    </div>
                </GridColumnContainer>
            }
            {/*If isPerson is no, then it's a business so show business name*/}
            {
                watchLocationHome == false.toString() &&
                <GridColumnContainer columns={2}>
                    <div>
                        <Label htmlFor={"businessName"} >Business Name</Label>
                        <Input name={"businessName"} required={true} maxLength={40} error={errors?.businessName}/>
                    </div>
                </GridColumnContainer>
            }

            {
                props.canEnterSocialSecurityNumber &&
                <GridColumnContainer columns={2}>
                    <div>
                        <Label htmlFor={"socialSecurityNumber"} >Social Security Number/Federal Tax ID</Label>
                        <Input type={"password"} name={"socialSecurityNumber"} required={true} maxLength={11} error={errors?.socialSecurityNumber} autoComplete={'off'}/>
                    </div>
                    <div>
                        <Label htmlFor={"confirmSocialSecurityNumber"} >Confirm Social Security Number</Label>
                        <ConfirmInput type={"password"}
                                      fieldToMatch={getValues("socialSecurityNumber")!}
                                      maxLength={11}
                                      confirmationError={"Must match Social Security Number"}
                                      name={"confirmSocialSecurityNumber"}
                                      required={true}
                                      error={errors?.confirmSocialSecurityNumber}
                                      autoComplete={'off'}
                        />
                    </div>
                </GridColumnContainer>
            }

            <GridColumnContainer columns={2}>
                <div>
                    <Label htmlFor={"primaryPhone"} >Primary Phone Number</Label>
                    <ControlledPhoneInput name={"primaryPhone"} required={true} error={errors?.primaryPhone} />
                </div>
                <div>
                    <Label htmlFor={"alternatePhone"} >Secondary Phone Number</Label>
                    <ControlledPhoneInput name={"alternatePhone"} required={true} error={errors?.alternatePhone} />
                </div>
            </GridColumnContainer>

            <GridColumnContainer columns={2}>
                {
                    props.canChooseOperatingCompany &&
                    <div>
                        <Label htmlFor={"operatingCompanyAbbreviation"} >Operating Company</Label>
                        <Select name={"operatingCompanyAbbreviation"} required={true} error={errors?.operatingCompanyAbbreviation}>
                            <StyledOption key={''} value={''}>Select One</StyledOption>
                            {
                                operatingCompanies.map((operatingCompany : OperatingCompany) =>
                                    <StyledOption key={operatingCompany.id} value={operatingCompany.abbreviation}>
                                        {operatingCompany.name}
                                    </StyledOption>
                                )
                            }
                        </Select>
                    </div>
                }
                {
                    props.canChooseEmail &&
                    <div>
                        <Label htmlFor={"email"} >Email</Label>
                        <Input type="email" name={"email"} required={true} pattern={Pattern.Email} maxLength={40} error={errors?.email}/>
                    </div>
                }
            </GridColumnContainer>
        </FormProvider>
    )
}