import React, {EventHandler, ReactElement, useEffect, useState} from "react";
import {Controller, FieldError, FieldErrors, useFormContext} from "react-hook-form";
import {
    FormFieldError, Label,
    StyledCheckboxOrRadioInput,
    StyledInput, StyledOption, StyledPhoneInput,
    StyledSelect,
    StyledTextArea,
    WrapperSpan
} from "../../styledcomponents/FormStyledComponents";
import {isValidPhoneNumber} from "react-phone-number-input";
import {DetailP, DropdownItem, FlexRowContainer} from "../../styledcomponents/MiscStyledComponents";
import {Pattern} from "../../enums/Pattern";
import Info from "../../assets/images/info.svg";
import {Tooltip as ReactTooltip} from "react-tooltip";
import styled from "styled-components";
import {AxiosError, AxiosResponse} from "axios";
import {useAxios} from "../../AxiosProvider";
import {useMessageService} from "../../hooks/useMessageService";
import {useAuth} from "../../auth/AuthProvider";

const InfoImg = styled.img`
    height: 1rem;
    vertical-align: middle;
    margin-left: 10px;
`

interface InputProps extends React.HTMLProps<HTMLInputElement> {
  name?: string;
  required?: boolean;
  maxLength?: number;
  minLength?: number;
  error?: FieldError;
  isNumber?: boolean;
  isBoolean?: boolean;
  placeholder?: string;
  enforceMaxLength?: boolean;
  autoComplete ?: string;
}

/** Returns the displayed contents of a corresponding label. */
function getLabelValue(name:string) {
    return document.querySelector(`label[for='${name}']`)?.innerHTML?.replace(/(\(Required\))|(\(Optional\))/, "")
}

function Input(props: InputProps) {
    const { register } = useFormContext(); // retrieve all hook methods

    let field: any;
    let labelValue = getLabelValue(props.name!)
    if (props.name) {
         field = {
            ...register(props.name, {
                required: props?.required! ?? false,
                maxLength: props.maxLength,
                minLength: props.minLength,
                pattern: {
                    value: RegExp(props.pattern ?? ''),
                    message: "pattern"
                }
            }),
        }
    }

    //if the parent component has provided an onChange function in props, combine that with the natural field onChange from react-hook-forms
    const combinedOnChange = props.onChange     
        ? ((e: React.FormEvent<HTMLInputElement>) => {props.onChange!(e); field.onChange(e)}) 
        :  field?.onChange
    if (props.type === "checkbox" || props.type === "radio") {
        return (
            <React.Fragment>
                <StyledCheckboxOrRadioInput
                    {...field}
                    type={props.type}
                    disabled={props.disabled}
                    value={props.value}
                    key={props.key}
                    onChange={combinedOnChange}
                    defaultChecked={props.defaultChecked}
                    aria-label={props["aria-label"]}
                    id={props.id}
                />
                {
                    (props.error?.type === "required" && props.required) &&
                    <FormFieldError>{labelValue ?? "Field"} is required.</FormFieldError>
                }
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <StyledInput
                id={props.id ?? props.name}
                {...field}
                type={props.type}
                value={props.value}
                onChange={combinedOnChange}
                disabled={props.disabled}
                aria-label={props["aria-label"]}
                placeholder={props.placeholder}
                maxLength={props.enforceMaxLength ? props.maxLength : null}
                autoComplete={props.autoComplete}
            />
            {
                (props.error?.type === "required" && props.required) &&
                <FormFieldError>{labelValue ?? "Field"} is required.</FormFieldError>
            }
            {
                (props.error?.type === "maxLength" || props.error?.type === "minLength") &&
                (props.maxLength === props.minLength
                    ? <FormFieldError> {labelValue ?? "Field"} must be {props.maxLength} characters.</FormFieldError>
                    : <FormFieldError> {labelValue ?? "Field"} must be between {props.minLength ?? 1}-{props.maxLength} characters.</FormFieldError>)
            }
            {
                props.error?.message === "pattern" &&
                <FormFieldError>
                    {labelValue ?? "Field"} is invalid.
                </FormFieldError>
            }
            {
                props.error?.type === "custommeessage" &&
                <FormFieldError>
                    {props.error.message}
                </FormFieldError>
            }
        </React.Fragment>
    )
}

function CheckboxInput(props: InputProps) {
    return <Input type={"checkbox"} aria-label={props.name} {...props} />
}

function RadioInput(props: InputProps) {
    return <Input type={"radio"} {...props} />
}

interface SelectProps extends React.HTMLProps<HTMLSelectElement> {
    name: string
    required?: boolean
    error?: FieldError
    usesTags?: boolean
}

function Select(props: SelectProps) {
    const { register, setValue } = useFormContext() // retrieve all hook methods

    let field
    let labelValue
    //get name of variable that holds the description value (if the provided field is an identifier)
    const descriptionName = props.name?.endsWith("identifier") ? props.name.replace("identifier", "description") : null;
    const tagsName = props.name?.endsWith("identifier") ? props.name.replace("identifier", "tags") : null;

    field = {
        ...register(props.name, {required: props?.required! ?? false, onChange: e => {
                //since this select only directly controls the identifier, we need to manually change the description if applicable
                if (descriptionName && props.children) {
                    //get the shown text of the selected option (e.g. "Hydro")
                    const desc = e.target.selectedOptions?.[0]?.innerHTML;
                    //set the description ref to our value
                    setValue(descriptionName, desc);
                }
                if (tagsName && props.usesTags) {
                    const tags = (e.target.selectedOptions?.[0]?.attributes['data-tags']?.value ?? '').split(',');
                    setValue(tagsName, tags);
                }
            }}),
    }
    labelValue = document.querySelector(`label[for='${props.name}']`)?.innerHTML

    return (
        <React.Fragment>
            <StyledSelect {...field} id={props.id ?? props.name} aria-label={props["aria-label"] } disabled={props.disabled}>
                {props.children}
            </StyledSelect>
            {
                props.error?.type === "required" &&
                <FormFieldError>{labelValue ?? "Field"} is required.</FormFieldError>
            }
        </React.Fragment>
    )
}

interface TextAreaProps extends React.HTMLProps<HTMLTextAreaElement> {
    name?: string
    required?: boolean
    error?: FieldError
    resizable?: boolean
}

function TextArea(props: TextAreaProps) {
    const { register } = useFormContext() // retrieve all hook methods

    if (props.name === undefined) {
        throw new Error("Name must be provided to a textarea")
    }

    let labelValue = document.querySelector(`label[for='${props.name}']`)?.innerHTML

    return (
        <React.Fragment>
            <StyledTextArea maxLength={props.maxLength} resizable={props.resizable ?? true} id={props.id ?? props.name} {...register(props.name, {required: props?.required! ?? false})} />
            {
                props.error?.type === "required" &&
                <FormFieldError>{labelValue ?? "Field"} is required.</FormFieldError>
            }
        </React.Fragment>
    )
}

type PhoneInputProps = {
    name : string
    error?: FieldError;
    required ?: boolean
}

function ControlledPhoneInput(props : PhoneInputProps) {
    const { control } = useFormContext() // retrieve all hook methods
    
    let labelValue = getLabelValue(props.name);

    return (
        <React.Fragment>
            <Controller
                control={control}
                name={props.name}
                rules={{
                  required: props?.required! ?? false,
                  minLength: 10,
                  maxLength: 12,
                  validate: (value) => {
                    return isValidPhoneNumber(value);
                  }
                }}
                render={({ field: { onChange, value, onBlur } }) => (
                    <StyledPhoneInput
                        value={value}
                        international={false}
                        onChange={(e: any) => {onChange(e ?? '')}}
                        defaultCountry="US"
                        id={props.name}
                        maxLength={16}
                        onBlur={()=> onBlur()}
                        limitMaxLength
                        
                    />
                )}
            />
        {props.error?.type === "required" && <FormFieldError>{labelValue ?? "Field"} is required.</FormFieldError>}
        {props.error?.type && props.error?.type !== "required" && <FormFieldError>{labelValue ?? "Field"} is invalid</FormFieldError>}
      </React.Fragment>
  )
}

type PasswordInputProps = {
    name : string
    error?: FieldError
    required ?: boolean
    showDetail ?: boolean
    autoComplete ?: string
}

function PasswordInput(props : PasswordInputProps) {
    const { register } = useFormContext() // retrieve all hook methods

    let labelValue = getLabelValue(props.name);

    const rulesString = 'Password must be between 8-20 characters, include at least 1 number, 1 uppercase letter, 1 lowercase letter, and 1 special character.';
    const isInvalid = ['minLength', 'maxLength', 'pattern'].includes(props.error?.type ?? '');

    return (
        <React.Fragment>
            <StyledInput autoComplete={props.autoComplete} type="password" maxLength={20} {...register(props.name, {
                required: props?.required! ?? false,
                minLength: 8,
                maxLength: 20,
                pattern: {
                    value: RegExp(Pattern.Password),
                    message: "pattern"
                }
            })} />
            {
                (props.showDetail && !isInvalid) &&
                <DetailP>{rulesString}</DetailP>
            }
            {
                (isInvalid) &&
                <FormFieldError>{rulesString}</FormFieldError>
            }
            {props.required && props.error?.type === "required" && <FormFieldError>{labelValue ?? "Field"} is required.</FormFieldError>}
        </React.Fragment>
    )
}

type ConfirmPasswordInputProps = PasswordInputProps & {
    password : string
}

function ConfirmPasswordInput(props : ConfirmPasswordInputProps) {
    return (
        <ConfirmInput name={props.name}
                      type={"password"}
                      maxLength={20}
                      fieldToMatch={props.password}
                      confirmationError={"Passwords must match."}
                      required={props.required}
                      error={props.error}
                      autoComplete={props.autoComplete}
        />
    )
}

type ConfirmInputProps = InputProps & {
    name : string
    fieldToMatch : string
    confirmationError : string
}

function ConfirmInput(props : ConfirmInputProps) {
    const { register } = useFormContext() // retrieve all hook methods

    let labelValue = getLabelValue(props.name);

    return (
        <React.Fragment>
            <StyledInput type={props.type ?? 'text'} maxLength={props.maxLength}  autoComplete={props.autoComplete} {...register(props.name,
                {
                    required: props?.required! ?? false,
                    validate: (value) => {
                        return props.fieldToMatch === value || props.confirmationError
                    }
                })} />
            {props.error?.type === "required" && <FormFieldError>{labelValue ?? "Field"} is required.</FormFieldError>}
            {props.error?.message === props.confirmationError && <FormFieldError>{props.confirmationError}</FormFieldError>}
        </React.Fragment>
    )
}

type LabelWithTooltipProps = {
    for : string
    label : string;
    tooltip : string;
}

function LabelWithTooltip(props : LabelWithTooltipProps) {
    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <Label htmlFor={props.for}>{props.label}</Label>
            <InfoImg src={Info} data-tooltip-id={`${props.for}-tooltip`} alt="info"/>
            <ReactTooltip
                id={`${props.for}-tooltip`}
                place="top"
                style={{width: '180px'}}
                content={props.tooltip}
            />
        </div>
    )
}

type ServerLoadedSelectProps = SelectProps & {
    url : string
    name : string
    usesTags ?: boolean
    forceId ?: string
}

function ServerLoadedSelect(props : ServerLoadedSelectProps) {
    const axios  = useAxios();
    const auth = useAuth();
    const messageService = useMessageService();
    const { resetField, setValue } = useFormContext() // retrieve all hook methods

    let labelValue = getLabelValue(props.name);

    const [dropdownItems, setDropdownItems] = useState<DropdownItem[]>([]);

    function sanitizeIdentifierCapitalization(response: any): DropdownItem[] {
        //When we call the API to get the allowed dropdown options, convert "Identifier" to "identifier"
        return response.map((r:any) => ({
            description: r.description,
            identifier: r.identifier ?? r.Identifier,
            tags: r.tags
        }));
    }

    useEffect(() => {
        if (props.forceId) {
            setValue(props.name.replace(/\.identifier$/, ''), dropdownItems.find(dd => dd.identifier === props.forceId));
        }
    }, [props.forceId]);

    // Fetch the dropdown item data. This will run when the component is mounted and also when the access token changes.
    // When a request is made with an expired access token, a new one will be generated and then that request will be reattempted.
    // When a form has multiple server loaded selects, they will all make this call at once and all fail when the access token is expired.
    // It seems that one select will get the new token and make the call again with success but any others will not get the new token.
    // If we tell this useEffect to run when the token changes then all the selects will be able to get it and make the call successfully.
    useEffect(() => {
        if (dropdownItems.length === 0) {
            let isRestricted = props.url.indexOf("unrestricted") == -1

            var axiosCall;

            if (isRestricted) {
                axiosCall = axios?.secureApi.get(props.url);
            } else {
                axiosCall = axios?.openApi.get(props.url);
            }

            axiosCall?.then(response => {
                setDropdownItems(sanitizeIdentifierCapitalization(response.data));
            }).catch((err : AxiosError) => {
                let errorResponse : AxiosResponse | undefined = err.response;

                if (errorResponse?.status != 401) {
                    messageService.error(`Error loading ${labelValue}`);
                }
            })
        }
    }, [auth.user?.accessToken]);

    useEffect(() => {
        resetField(props.name);
    }, [dropdownItems])

    return (
        <Select {...props}>
            <StyledOption key={''} value={''}>Select One</StyledOption>
            {
                dropdownItems.map((item : DropdownItem) =>
                    //ts-ignore
                    <StyledOption key={item.description} value={item.identifier} tags={(item.tags ?? []).join(',')}>
                        {item.description}
                    </StyledOption>
                )
            }
        </Select>
    )
}

type SimpleRadioProps =  {
    label : string | JSX.Element
    tooltip ?: string
    fieldName : string
    /** Defaults to Yes/No.  */
    choices ?: {display: string, value: any}[] 
    optional ?: boolean //since most radios are required, I opted to specify optional instead of required
    compact ?: boolean
    forceValue ?: any
}

/** Radio question which defaults to Yes/No. Provides values in boolean */
function SimpleRadio(props: SimpleRadioProps) {
    const choices = props.choices ?? [{display: 'Yes', value: true}, {display: 'No', value: false}]
    let question : JSX.Element;
    if (props.tooltip && typeof props.label === 'string') {
        
        question = <LabelWithTooltip for={String(props.label)} label={props.label} tooltip={props.tooltip} />
    } else {
        question = typeof props.label === 'string' ? <b>{props.label}</b> : props.label;
    }

    const { control, setValue } = useFormContext()
    useEffect(() => {
        if (props.forceValue != null) {
            setValue(props.fieldName, props.forceValue);
        }
    }, [props.forceValue])
    return <div id={String(props.label)}>
            {question}
            <div style={{height: '0.2em'}}></div>
            <FlexRowContainer gap={props.compact ? 0.2 : undefined }>
                {choices.map(c => 
                    <Label key={c.display} htmlFor={props.fieldName + c.display} checkbox={true} >
                        
                        <Controller control={control} name={props.fieldName}
                            rules={{
                                validate: val => props?.optional || (val !== undefined && val !== null), //normal required validation considers "false" as missing
                            }}
                            render={({ field: { onChange, value } }) => (
                                <StyledCheckboxOrRadioInput type="radio" name={props.fieldName} id={props.fieldName + c.display} checked={value === c.value || value?.toString() === c.value} 
                                    required={!props.optional} onChange={e=> onChange(c.value)} compact={props.compact} disabled={props.forceValue != null}
                                />
                            )}
                        />
                        {c.display}
                    </Label>
                )}
            </FlexRowContainer>
        </div>
}

function TooltipValueInput(props: InputProps & {tooltip?: string, label: string, errorParent: FieldErrors, isDcFieldName ?: string, pattern ?: Pattern, maxLength ?: number}){
    //this is scary, but so is having to manually specify each error path when already providing the name.
    //@ts-ignore
    const error : FieldError = props.name!.split('.').reduce((a, b) => a?.[b], props.errorParent);
    
    let inputBox = <Input name={props.name} required={props.required ?? true} pattern={props.pattern ?? Pattern.KwPrecisionMeasure} 
            error={props.isDcFieldName ? undefined : error} disabled={props.disabled} maxLength={props.maxLength}/>;
    let errorComponent = null;
    if (props.isDcFieldName) {
        //@ts-ignore
        const isDcError : FieldError = props.isDcFieldName!.split('.').reduce((a, b) => a?.[b], props.errorParent);
        inputBox = 
        <div style={{display:"flex"}}>
            {inputBox}
            <SimpleRadio label={''} fieldName={props.isDcFieldName} choices={[{display: 'DC', value: true}, {display: 'AC', value: false}]} compact/>
            <div style={{width:'2em'}}></div>
        </div>

        if (error?.type) { //With the AC/DC radio, we have to recreate the error message, otherwise it will appear in between the box and radio.
            errorComponent = <FormFieldError>{`${props.label} is ${error.type == 'required' ? 'required' : 'invalid'}.`}</FormFieldError>
        } else if (isDcError?.type) {
            errorComponent = <FormFieldError>Please select AC or DC.</FormFieldError>
        }
    }
    return <div>
                                
        {props.tooltip ? <LabelWithTooltip for={props.name!} label={props.label} tooltip={props.tooltip}/>
        : <Label htmlFor={props.name!}>{props.label}</Label>}
        {inputBox}
        {errorComponent}
    </div>
}

/**Executes an event if the user hovers over the content of this span for more than half a second */
function HoverSpan(props: {onHover: EventHandler<any>, children: ReactElement|string}) {
    const [hoverEvent, setHoverEvent] = useState<NodeJS.Timeout|null>(null);

    function onHover() {
        if (hoverEvent) {
            clearTimeout(hoverEvent);
        }
        setHoverEvent(setTimeout(() => props.onHover(''), 500));
    }

    function onLeave() {
        if (hoverEvent) {
            clearTimeout(hoverEvent);
            setHoverEvent(null);
        }
    }

    return <WrapperSpan onMouseEnter={() => onHover()} onMouseLeave={() => onLeave()}>
        {props.children}
    </WrapperSpan>;
}

function NewUsernameField(props: Readonly<{name: string, error?: FieldError, label?: string}>) {
    const usernameRules = 'Must be between 6-15 letters or numbers, but no special characters (@, &, -, etc.) or spaces.';
    const usernameInvalid = ['minLength', 'maxLength', 'pattern'].includes(props.error?.type ?? '');
    const usernameMissing = ['required'].includes(props.error?.type ?? '');

    return <div>
        <Label htmlFor={"username"} >{props.label ?? 'Username'}</Label>
        <Input name={"username"} required={true} maxLength={15} pattern={'^[A-Za-z0-9]+$'} minLength={6} enforceMaxLength={true} />
        {usernameInvalid ? <FormFieldError>{usernameRules}</FormFieldError> : <DetailP>{usernameRules}</DetailP>}
        {usernameMissing && <FormFieldError>Username is required.</FormFieldError>}
    </div>
}

export { Input, CheckboxInput, RadioInput, Select, TextArea, ControlledPhoneInput, PasswordInput, ConfirmPasswordInput, ConfirmInput, LabelWithTooltip, ServerLoadedSelect, SimpleRadio, TooltipValueInput, HoverSpan, NewUsernameField }
